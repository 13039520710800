import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TranslocoRootModule} from './transloco-root.module';
import {FormsModule} from "@angular/forms";
import {MobileMenuComponent} from "./shared/components/header/mobile-menu/mobile-menu.component";
import {HomePageModule} from "./pages/home/home.module";
import {HttpInterceptorProvider} from "./services/mygreencountry/http.interseptor";
import {SharedModule} from "./shared/shared.module";
import {StudentMenuComponent} from "./shared/components/header/student-menu/student-menu.component";

@NgModule({
    declarations: [AppComponent, MobileMenuComponent, StudentMenuComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        TranslocoRootModule,
        FormsModule,
        SharedModule
    ],
    providers: [
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorProvider, multi: true},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
