import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {AuthApiResponse} from "../../types/apiResponse.interface";
import {API_URL} from "../../../providers/config";

const apiUrl = API_URL;

@Injectable({
  providedIn: 'root'
})
export class ApiService {



  constructor(
    private http: HttpClient,
  ) {
  }


  public sendApiRequest(method: string, postData: any, typeMethod: string = 'get') {

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    let options = {
      headers: headers
    };

    let body = JSON.stringify(postData);

    let result;
    switch (typeMethod) {
      case 'get':
        result = this.http.get(apiUrl + method, options);
        break;
      case 'post':
        result = this.http.post(apiUrl + method, body, options);
        break;
      case 'put':
        result = this.http.put(apiUrl + method, body, options);
        break;
      case 'delete':
        result = this.http.delete(apiUrl + method, options);
        break;
      default:
        result = this.http.post(apiUrl + method, body, options);
    }
    return result;
  }

}
