import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {inject, Injectable} from "@angular/core";
import {MyGreenCountry} from "../services/mygreencountry/my-greencountry";
import {NavController} from "@ionic/angular";
import {Observable} from "rxjs";
import {AuthService} from "../services/mygreencountry/auth.service";
import {PersistenceService} from "../services/mygreencountry/persistence.service";

@Injectable({
  providedIn: 'root'
})

class LoginGuardService {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const authService = inject(AuthService);
    const persistenceService = inject(PersistenceService);
    const navCtrl = inject(NavController);

    return new Promise<boolean>(async (resolve, reject) => {

      if (!authService.userIsAuthorized()) {
        await navCtrl.navigateRoot('/login');

        resolve(false);
      } else {
        if (!authService.checkUser()) {
          persistenceService.remove('token');
          await navCtrl.navigateRoot('/login');

          resolve(false);
        } else {
          resolve(true);
        }
      }
    });
  }
}

export const loginGuard: CanActivateFn = (route, state) => {
  return inject(LoginGuardService).canActivate(route, state);
};
