import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "../services/mygreencountry/auth.service";
import {NavController} from "@ionic/angular";
import {catchError, of, switchMap} from "rxjs";
import {PersistenceService} from "../services/mygreencountry/persistence.service";

// @ts-ignore
export const tokenAuthGuard: CanActivateFn = (route, state) => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const navCtrl = inject(NavController);
    const persistenceService = inject(PersistenceService);


    const token = route.paramMap.get('token');

        if (token) {

            if (authService.userIsAuthorized()){
                authService.logout();
            }

            authService.authWithToken(token)

                .subscribe({

                    next: (response) => {

                        if (response) {
                            return router.navigate(['/login'], {
                                queryParams: {authorized: true }
                            });

                        } else {
                            return navCtrl.navigateRoot('/login')
                        }
                    },

                    error: (error) => {

                        console.error('Auth error:', error);
                        return navCtrl.navigateRoot('/login')

                    },

                    complete: () => {

                    }

                })
            // Maybe set it in the service or use it for some purpose
        } else {
            // return  navCtrl.navigateRoot('/login')
        }

        return false;
        // return true;/
        //



};



