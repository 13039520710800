import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {MyGreenCountry} from "../services/mygreencountry/my-greencountry";
import {NavController} from "@ionic/angular";
import {AuthService} from "../services/mygreencountry/auth.service";

export const authGuard: CanActivateFn = (route, state) => {

  const authService = inject(AuthService);
  const router = inject(Router);

  if (!authService.userIsAuthorized()) {

      // return router.parseUrl('/login');
    //
    return true;

  } else {

    return router.parseUrl('/home');
      //
      // return true;
  }

};
