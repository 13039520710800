import {NgModule} from "@angular/core";
import {ChatsComponent} from "./chats/chats.component";
import {HeaderComponent} from "./header/header/header.component";
import {FooterComponent} from "./footer/footer.component";
import {StudentActionsComponent} from "./student-actions/student-actions.component";
import {IonicModule} from "@ionic/angular";
import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {TranslocoDirective, TranslocoPipe} from "@jsverse/transloco";
import {StudentRequestComponent} from "./modals/student-request/student-request.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IntlTelInputNgModule} from "intl-tel-input-ng";
import {BannersComponent} from "./banners/banners.component";
import {NoCourseNoticeComponent} from "./no-course-notice/no-course-notice.component";
import {EnglishLevelsComponent} from "./modals/english-levels/english-levels.component";


@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        RouterModule,
        TranslocoDirective,
        TranslocoPipe,
        FormsModule,
        IntlTelInputNgModule,
        ReactiveFormsModule
    ],

    exports: [
        ChatsComponent,
        HeaderComponent,
        FooterComponent,
        StudentActionsComponent,
        TranslocoDirective,
        TranslocoPipe,
        StudentRequestComponent,
        BannersComponent,
        NoCourseNoticeComponent,
    ],
    declarations: [
        ChatsComponent,
        HeaderComponent,
        FooterComponent,
        StudentActionsComponent,
        StudentRequestComponent,
        BannersComponent,
        NoCourseNoticeComponent,
        EnglishLevelsComponent,
    ]
})
export class ComponentsModule {
}
